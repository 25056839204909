@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.3;
}
body {
  background-color: #2c3e50;
  color: white;
  font-family: 'League Spartan', sans-serif;
  transition: .5s ease-out;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}